<template>
  <div class="">
    <!--Filters-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <b-row no-gutters class="w-100">
          <b-col>
            <h5>{{ msg("Filters") }}</h5>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <b-link style="color: inherit" @click="refresh()">
                <feather-icon icon="RefreshCcwIcon" size="16"/>
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ msg("Model") }}</label>
            <b-form-input
                v-model="filter.like.model"
                class="d-inline-block mr-1"
            />
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ msg("Network") }}</label>
            <b-form-input
                v-model="filter.like.network"
                class="d-inline-block mr-1"
            />
          </b-col>

          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ msg('Contract type') }}</label>
            <v-select
                v-model="filter.in.contractType"
                :options="[
                { value: 'simRouterNoContract', text: msg('Sim Router (No Contract)') },
                { value: 'simRouterWithContract', text: msg('Sim Router (With Contract)') },
                { value: 'fiberInternet', text: msg('Fiber Internet (With Contract)') }
              ]"                class="w-100"
                label="text"
                :reduce="(status) => status.value"
                multiple
            />
          </b-col> <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ msg('Status') }}</label>
            <v-select
                v-model="filter.in.status"
                :options="routerStatus"
                class="w-100"
                label="text"
                :reduce="(status) => status.value"
                multiple
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <div class="card-header" style="overflow: hidden">
        <i class="fas fa-wifi" style="margin-right: 3px"></i>

        <span class="card-title m-0">{{ msg('Routers') }}</span>
        <b-col>
          <div class="d-flex justify-content-end">
            <b-link style="color: inherit" @click="refresh()">
              <feather-icon icon="RefreshCcwIcon" size="16"/>
            </b-link>
          </div>
        </b-col>
      </div>
      <!--Card Content-->
      <b-card-body>
        <div class="">
          <!-- Table Top -->
          <b-row no-gutters class="mb-1">
            <!-- Per Page -->
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>{{ msg('Show') }}</label>
              <v-select
                  v-model="page.amount"
                  :options="[10, 25, 50, 100]"
                  :clearable="false"
                  @option:selected="refresh"
                  class="per-page-selector d-inline-block mx-50"
              />
              <label>{{ msg('entries') }}</label>
            </b-col>
            <!-- Search -->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <span class="mr-1"></span>
                <b-button variant="primary" @click="onNewRouter(0)">
                  <i class="fas fa-wifi" style="margin-right: 2px"></i>

                  <span class="text-nowrap">{{ msg('New Router') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>
        <!--Table-->
        <b-table
            ref="router-table"
            striped
            hover
            responsive
            v-bind:items="routers"
            v-bind:fields="fields"
            :busy="loading"
            @sort-changed="onSort"
            @row-clicked="onRowClick"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mx-1"></b-spinner>
              <strong>{{ msg('Loading') }}...</strong>
            </div>
          </template>
          <template #cell(imageUri)="data">
            <div>
              <b-img :src="data.value" fluid thumbnail style="max-width: 50px; max-height: 50px"/>
            </div>
          </template>

          <template #cell(lastCharge)="data">
            {{ $moment(data.value, 'YYYY-MM-DD').format("DD.MM.YYYY") }}
          </template>
          <template #cell(contractType)="data">
            <span style="white-space: nowrap">{{ getContractTypeLabel(data.value) }}</span>
          </template>

          <template #cell(actions)="row">
            <b-button
                size="sm"
                @click="openConfirmationModal('delete', row.item)"
                class="btn btn-danger mr-1"
                v-b-tooltip.hover
                :title="msg('Delete')"
            >
              <i class="fa fa-trash" aria-hidden="true"></i>
            </b-button>
          </template>
          <template #cell(realEstateId)="data">
            <b-link style="font-weight: bold" v-if="data.value!=null && data.value!==''"
                    @click="routeToProperty(data.value)" variant="danger">
              {{ realEstates[data.value].name }}
            </b-link>
          </template>

          <template #cell(status)="data" class="p-0">
            <b-badge :variant="statusVariants[data.value]">{{ msg(data.value) }}</b-badge>
          </template>

        </b-table>

        <!--Table Paginator-->
        <div class="text-center w-100">
          <div class="d-inline-block">
            <b-pagination
                v-model="page.currentPage"
                :total-rows="page.total"
                :per-page="page.amount"
                first-number
                last-number
                @input="refresh"
            />
          </div>
          <div class="d-inline-block px-2"><strong>{{ msg('Total') }}</strong> {{ page.total }}</div>
        </div>
      </b-card-body>
    </b-card>
    <b-modal no-fade v-model="dialogs.newRouter" size="lg" ref="router-modal" id="router-modal"
             :hide-footer="true" no-close-on-backdrop centered>
      <router-panel ref="routerPanel" @submitted="onSubmitted"/>
    </b-modal>
    <b-modal no-fade v-model="dialogs.updateRouter" size="lg" ref="router-mofddal" id="roudfter-modal"
             :hide-footer="true" no-close-on-backdrop centered>
      <edit-router-panel ref="routefdrPanel" @submitted="onSubmitted" :id="selected"/>
    </b-modal>

    <b-modal
        v-model="showConfirmationModal"
        :title="msg('Confirm Action')"
        @cancel="closeModal"
        centered
        :cancel-title="msg('Cancel')"
        ok-variant="success"
        cancel-variant="danger"
        @ok="confirmAction"
    >
      <div>
        <p>{{ modalMessage }}</p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import RouterPanel from "./routerPanel.vue";
import EditRouterPanel from "@/views/realEstate/router/editRouterPanel.vue";

export default {
  name: 'Routers',
  components: {RouterPanel, EditRouterPanel},
  data() {
    return {
      loading: true,
      showConfirmationModal: false,
      modalEvent: '', // To store the event type (e.g., 'charge', 'delete', etc.)
      modalMessage: '', // The dynamic message based on the event type
      selectedItem: null, // The item being acted upon

      contractTypes: [
        { value: 'simRouterNoContract', text: ('Sim Router (No Contract)') },
        { value: 'simRouterWithContract', text: ('Sim Router (With Contract)') },
        { value: 'fiberInternet', text: ('Fiber Internet (With Contract)') },
      ],
      amount: 10,
      routers: [],
      fields: [],
      selected: null,
      realEstates: {},

      filter: {
        like: {search: ''},
        eq: {},
        lte: {},
        gte: {},
        in: {status: []}
      },
      sort: {
        by: 'creationDate',
        desc: true,
        direction: 'desc'
      },
      statusVariants: {

        "online": "success",
        "offline": "secondary",
        "inProgress": "warning",
        "error": "danger"
      },


      page: {
        amount: 10,
        currentPage: 1,
        total: 0
      },
      dialogs: {newRouter: false, updateRouter: false},
      //Listeners
      listeners: {
        refresh: () => {
        }
      }

    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.refresh()
      }
    },
    file: {
      handler() {
      }
    }
  },
  computed: {
    ...mapGetters('data', ['getOrganizationWebSite', 'getRealEstatesWebSite']),
    ...mapGetters('data', ['getUserFullName', 'getUserAcronyms']),
    ...mapGetters('data', ['getAllUsers', 'getUsersInTeam', 'getUserRole', 'getRoles', 'getRoleName']),
    ...mapGetters('translator', ['msg']),
    ...mapGetters('router', ['allRouterStatus']),

    routerStatus() {
      let $this = this;
      return this.allRouterStatus.map(status => ({
        value: status.value,
        text: $this.msg(status.text)
      }))
    },
  },
  created() {
    let $this = this;
    this.setFields();
    this.refresh()
    this.$root.$on('crm::branch-updated', (this.listeners.refresh = () => {
      $this.refresh()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::branch-updated', this.listeners.refresh)
  },
  methods: {
    ...mapActions('router', ['getAllRouters', 'copySuccess','deleteService']),
    ...mapActions('clients', ['getClients']),

    openConfirmationModal(event, item) {
      // Set the event type and selected item
      this.modalEvent = event;
      this.selectedItem = item;
      // Dynamically set the message based on the event
      if (event === 'delete') {
        this.modalMessage = this.msg('Are you sure you want to delete this service?');
      }

      // Show the confirmation modal
      this.showConfirmationModal = true;
    },
    closeModal() {
      // Close the confirmation modal
      this.showConfirmationModal = false;
    },
    confirmAction() {
      // Call the actual function based on the event
      if (this.modalEvent === 'delete') {
        this.handleEvent('delete', this.selectedItem);
      }
      // Close the modal after action
      this.closeModal();
    },
    handleEvent(event,item) {
      let $this = this;
      this.deleteService({id: item.id}).then(() => $this.refresh())
    },
    getContractTypeLabel(value) {
      const contract = this.contractTypes.find(item => item.value === value);
      return contract ? contract.text : value; // Return the label or the value if not found
    },
    routeToProperty(propertyId) {
      window.open(`/real_estate/edit/${propertyId}`, '_blank');
    },
    onCopy: function (e) {
      this.copySuccess(e.text)
    },
    onError: function (e) {
      alert('Failed to copy the text to the clipboard')
    },

    setFields() {
      let $this = this;

      this.fields = [
        {
          key: 'imageUri',
          label: $this.msg('imageUri'),
          sortable: false
        },
        {
          key: 'status',
          label: $this.msg('status'),
          sortable: true
        },
        {
          key: 'realEstateId',
          label: $this.msg('realEstate'),
          sortable: true
        },
        {
          key: 'model',
          label: $this.msg('Model'),
          sortable: true
        },
        {
          key: 'network',
          label: $this.msg('Network'),
          sortable: true
        },
        {
          key: 'wifiName',
          label: $this.msg('Wifi Name'),
          sortable: true
        },
        {
          key: 'wifiPassword',
          label: $this.msg('Wifi Password'),
          sortable: false
        },
        {
          key: 'phone',
          label: $this.msg('Phone'),
          sortable: true
        },
        {
          key: 'pin',
          label: $this.msg('Pin'),
          sortable: false
        },
        {
          key: 'puk',
          label: $this.msg('Puk'),
          sortable: false
        },
        {
          key: 'simcardNumber',
          label: $this.msg('Sim card number'),
          sortable: false
        },
        {
          key: 'routerPassword',
          label: $this.msg('Router password'),
          sortable: false
        },
        {
          key: 'lastCharge',
          label: $this.msg('Last charge'),
          sortable: true
        },
        {
          key: 'contractType',
          label: $this.msg('Contract type'),
          sortable: true
        },

        {
          key: 'loginLink',
          label: $this.msg('Login link'),
          sortable: false
        },

        {
          key: 'actions',
          label: $this.msg('Actions'),
          sortable: true
        },

        /*  {
            key: 'buid',
            label: $this.msg('branch'),
            sortable: true
          },
  */
      ];
    },

    copyId(id) {
      console.log("the id copied was: ", id)
    },

    refresh() {
      let $this = this
      this.loading = true;
      this.sort = {
        by: 'creationDate',
        desc: true,
        direction: 'desc'
      },
          this.getAllRouters({
            page: $this.page.currentPage,
            amount: $this.page.amount,
            filter: $this.filter,
            sortcolumn: $this.sort.by,
            sortby: $this.sort.direction,
          }).then(this.onRoutersRetrieve, (error) => $this.error = error)

    },
    onRoutersRetrieve(data) {
      this.page = {
        amount: data['Amount'],
        total: data['Total'],
        currentPage: data['Page']
      };
      this.sort = {
        by: data["sortColumn"],
        direction: data["sortOrder"]
      }
      this.routers = data["Routers"];
      this.realEstates = data["RealEstates"];
      this.loading = false;
    },

    onNewRouter(id) {

      this.dialogs.newRouter = true

    },
    onSort(data) {
      this.sort.by = data.sortBy;
      this.sort.direction = data.sortDesc ? "desc" : "asc";
      this.refresh();
    },
    onRowClick(router) {
      this.selected = router.id;
      this.dialogs.updateRouter = true
    },

    onSubmitted(server) {
      if (server.id != null)
        this.selected = server.id;
      this.refresh()
      this.dialogs.newRouter = false;
      this.dialogs.updateRouter = false;
    },
    onClientSelect(clientId) {
      this.$root.$emit('bv::hide::popover')
      this.$root.$emit('crm::client::select', clientId)
    },
  }
}
</script>

<style>

</style>
